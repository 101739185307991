<template>
  <div id="date-filter" class="card">
    <div class="card-header">
      <i class="fas fa-filter"></i> ตัวเลือกการค้นหา
    </div>
    <div class="card-body">
      <div class="row">
        <div :class="{'col-xl-6': withMarketId, 'col-12': !withMarketId}">
          <b-form-radio-group
            class="date-filter-radio"
            v-model="range"
          >
            <b-form-radio value="today">วันนี้</b-form-radio>
            <b-form-radio value="yesterday">เมื่อวาน</b-form-radio>
            <b-form-radio value="this-week">สัปดาห์นี้</b-form-radio>
            <b-form-radio value="last-week">สัปดาห์ที่แล้ว</b-form-radio>
          </b-form-radio-group>
          <div class="row mb-3">
            <div class="col-3 col-md-2 d-flex align-items-center">
              <b-form-radio v-model="range" name="some-radios" value="month">เดือน</b-form-radio>
            </div>
            <div class="col-9 col-md-10 form-inline">
              <flat-pickr
                :config="monthPickerConfig"
                v-model="selectedMonth"
                class="form-control form-control-sm ml-2"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-3 col-md-2">
              <b-form-radio v-model="range" name="some-radios" value="date">วันที่</b-form-radio>
            </div>
            <div class="col-9 col-md-10 form-inline between-date">
              <span class="start-date-box">
                <span class="pl-2 start-date-text">เริ่ม</span>
                <flat-pickr
                  :config="datePickerConfig"
                  v-model="startDateTh"
                  class="form-control form-control-sm ml-2"
                />
              </span>

              <span class="end-date-box">
                <span class="pl-2 end-date-text">ถึง</span>
                <flat-pickr
                  :config="datePickerConfig"
                  v-model="endDateTh"
                  class="form-control form-control-sm ml-2"
                />
              </span>
            </div>
          </div>
        </div>
        <div v-if="withMarketId" class="col-xl-6">
          <MarketFilter @submit="selectLottoIds" />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button class="btn btn-sm btn-info" @click="confirmDate"><i class="fas fa-search"></i> ค้นหา</button>
    </div>
  </div>
</template>
<script>
import moment from '@/helpers/moment'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {Thai as ThaiLocale} from 'flatpickr/dist/l10n/th.js'
import Swal from 'sweetalert2'
import MarketFilter from './MarketFilter'

export default {
  name: 'DateFilter',
  components: {
    flatPickr,
    MarketFilter
  },
  props: {
    withMarketId: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      range: moment().format("H") >= 5 ? 'today' : 'yesterday',

      selectedMonth: moment().format("MM/YYYY"),
      monthPickerConfig: {
        locale: ThaiLocale,
        plugins: [
          new monthSelectPlugin({
            dateFormat: "m/Y"
          })
        ],
        onChange: ()=>{
          this.range = 'month'
        }
      },

      selectedDate: moment().format("DD/MM/YYYY"),
      datePickerConfig: {
        // mode: "range",
        locale: ThaiLocale,
        dateFormat: "d/m/Y",
        onChange: ()=>{
          this.range = 'date'
        },
        // maxDate: moment().format("DD/MM/YYYY")
      },

      startDateTh: moment().format("DD/MM/YYYY"),
      endDateTh: moment().format("DD/MM/YYYY"),

      marketIds: []
    }
  },
  computed: {
    dataDate() {
      return {
        'today': ()=>{
          return {
            start: moment().format("YYYY-MM-DD"),
            end: moment().format("YYYY-MM-DD")
          }
        },
        'yesterday': ()=>{
          return {
            start: moment().add(-1, 'd').format("YYYY-MM-DD"),
            end: moment().add(-1, 'd').format("YYYY-MM-DD")
          }
        },
        'this-week': ()=>{
          const start = moment().startOf('week').format("YYYY-MM-DD")
          const end = moment().endOf('week').format("YYYY-MM-DD")
          return { start, end }
        },
        'last-week': ()=>{
          const start = moment().add(-1, 'w').startOf('week').format("YYYY-MM-DD")
          const end = moment().add(-1, 'w').endOf('week').format("YYYY-MM-DD")
          return { start, end }
        },
        'month': ()=>{
          const month = this.selectedMonth.split('/')
          const start = moment(`${month[1]}-${month[0]}`).startOf('month').format("YYYY-MM-DD")
          const end = moment(`${month[1]}-${month[0]}`).endOf('month').format("YYYY-MM-DD")
          return { start, end }
        },
        'date': ()=>{
          const start = (this.startDateTh || '').split('/')
          const end = (this.endDateTh || '').split('/')

          const dateStart = moment(`${start[2]}-${start[1]}-${start[0]}`).format("YYYY-MM-DD")
          const dateEnd = moment(`${end[2]}-${end[1]}-${end[0]}`).format("YYYY-MM-DD")

          return {
            start: dateStart,
            end: moment(dateEnd).isBefore(dateStart) ? dateStart : dateEnd
          }
        }
      }[this.range]()
    }
  },
  methods: {
    confirmDate() {
      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1
      if(diff > 60) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: 'ดูรายการได้ไม่เกิน 60 วัน',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      this.$emit('submit', {
        date: this.dataDate,
        marketIds: this.marketIds
      })
    },
    selectLottoIds(marketIds) {
      this.marketIds = marketIds
    }
  },
  mounted() {
    console.log(moment().format("h"))
    this.$emit('submit', {
      date: this.dataDate,
      marketIds: this.marketIds
    })
  }
}
</script>
<style lang="scss">
#date-filter {
  border-color: rgba(0, 0, 0, 0.125) !important;

  .card-header {
    padding: 5px;
    background-color: #F7F7F7 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }

  .card-footer {
    padding: 5px;
  }

  .date-filter-radio {
    .custom-radio {
      margin-bottom: 10px;
    }
  }

  .start-date-box {
    .start-date-text {
      display: none;
    }
  }

  .between-date {
    input.flatpickr-input {
      width: 120px;
    }
  }
}

@media (max-width: 575px) {
  #date-filter {

    .start-date-box {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 10px;

      .start-date-text {
        display: block;
        width: 40px;
      }
    }

    .end-date-box {
      display: flex;
      width: 100%;
      align-items: center;

      .end-date-text {
        display: block;
        width: 40px;
      }
    }
  }
}
</style>
